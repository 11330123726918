import { BrowserRouter, Route, Routes } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Home from "./page/Home";
import PostsPage from "./page/posts/PostsPage";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/:username/posts/:postId' element={<PostsPage />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
